import React, { useRef } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import AreaMap from "../components/areaMap";
import { SafeHtmlParser } from "../components/safe-html-parser";
import { Helmet } from "react-helmet";
import CallIcon from "../components/call-icon";
import Hero from "../components/hero";
import Certifications from "../components/certifications";
import QuickActions from "../components/quickActions";
import ReviewSection from "../components/review-section";
import CallNowBanner from "../components/callNowBanner";
import DisplayFaqs from "../components/displayFaqs";
import Messaging from "../components/messaging";

const AreaPostTemplate = ({ data }) => {
	const {
		post: {
			slug,
			title,
			seoFieldGroups,
			areasPostFields: { areasPostFields, hasShowMessageIcon },
		},
		site,
		schemaLogo,
		reviews,
		pageData,
	} = data;

	const siteUrl = site.siteMetadata.siteUrl;
	const reviewCount =
		pageData.reviewCountFields.checkatradeReviewCount +
		pageData.reviewCountFields.googleReviewCount +
		pageData.reviewCountFields.bwcReviewCount;

	const schemaLogoUrl = schemaLogo.publicURL;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}/${slug}`,
		image: `${siteUrl}${seoFieldGroups?.image.publicURL}`,
		mpn: `${siteUrl}/${slug}`,
		sku: `${siteUrl}/${slug}`,
		description: `${seoFieldGroups?.productSchema}`,
		logo: `${siteUrl}${schemaLogoUrl}`,
		name: "Seymour Locksmiths",
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${schemaLogoUrl}`,
			image: `${siteUrl}${seoFieldGroups?.image.publicURL}`,
			name: "Seymour Locksmiths",
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/our-locksmith-reviews`,
			datePublished: "2022-01-08T17:58:29+00:00",
			reviewBody:
				"What a fantastic service. I snapped the key in a lock that had been faulty for a while. Jeff arrived quickly and replaced the lock with plenty of spare keys and at a reasonable price. Nice friendly chap. I would definitely recommend and use again in the future. Thank you!",
			author: {
				"@type": "Person",
				name: "Francesca Richards",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Seymour Locksmiths",
				sameAs: `${siteUrl}`,
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Seymour Locksmiths",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: `Locksmith ${title}`,
				item: {
					url: `${siteUrl}/${slug}`,
					id: `${siteUrl}/${slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			{hasShowMessageIcon ? <Messaging /> : <CallIcon />}
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
				{seoFieldGroups?.metaKeywords && (
					<meta name="keywords" content={seoFieldGroups?.metaKeywords} />
				)}
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@jefflocksmith",
					site: "@jefflocksmith",
					cardType: "summary",
				}}
			/>

			{areasPostFields.length > 0 &&
				areasPostFields.map((sectionInfo) => (
					<>
						{sectionInfo.fieldGroupName ===
							"Area_Areaspostfields_AreasPostFields_BannerSection" && (
							<Hero
								Tag="section"
								text={<SafeHtmlParser htmlContent={sectionInfo?.description} />}
								image={
									sectionInfo?.backgroundImage.localFile.childImageSharp
										.gatsbyImageData
								}
								heading={sectionInfo?.heading}
								button={sectionInfo?.cta1}
								button2={sectionInfo?.cta2}
								actions={sectionInfo?.actions}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Area_Areaspostfields_AreasPostFields_SectionLayout2" && (
							<Certifications lists={sectionInfo?.trustedList} />
						)}

						{sectionInfo.fieldGroupName ===
							"Area_Areaspostfields_AreasPostFields_SectionLayout3" && (
							<Container
								fluid={true}
								className={`${
									sectionInfo.hasBlackBackground
										? "bg-black text-offwhite py-24"
										: "my-24"
								}`}
								as="section"
							>
								<Row className="justify-content-center">
									<Col xs={11} sm={10}>
										{sectionInfo?.heading && (
											<Row className="mb-5">
												<Col className="d-flex justify-content-center" xs={12}>
													<h2 className="text-3xl mb-3 fw-700 lato text-center">
														{sectionInfo?.heading}
													</h2>
												</Col>
												{!sectionInfo?.hasBlackBackground && (
													<Col
														className="d-flex justify-content-center"
														xs={12}
													>
														<span className="sl-underline d-block" />
													</Col>
												)}
											</Row>
										)}

										<Row className="align-items-center">
											<Col
												lg={sectionInfo?.image ? 10 : 12}
												className="two-column-content"
											>
												{sectionInfo?.description && (
													<SafeHtmlParser
														htmlContent={sectionInfo?.description}
													/>
												)}
											</Col>
											{sectionInfo?.image && (
												<Col className="text-center pt-4 pt-lg-0" lg={2}>
													{sectionInfo?.link && sectionInfo?.link?.url ? (
														<a
															target="_blank"
															rel="noreferrer"
															href={sectionInfo?.link?.url}
														>
															<GatsbyImage
																image={
																	sectionInfo.image?.localFile.childImageSharp
																		.gatsbyImageData
																}
																alt={sectionInfo.image?.altText}
															/>
														</a>
													) : (
														<GatsbyImage
															image={
																sectionInfo.image?.localFile.childImageSharp
																	.gatsbyImageData
															}
															alt={sectionInfo.image?.altText}
														/>
													)}
												</Col>
											)}
										</Row>
									</Col>
								</Row>
							</Container>
						)}

						{sectionInfo.fieldGroupName ===
							"Area_Areaspostfields_AreasPostFields_SectionLayout4" && (
							<QuickActions
								serviceSectionList={sectionInfo.serviceSectionList}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Area_Areaspostfields_AreasPostFields_SectionLayout5" && (
							<ReviewSection
								heading={sectionInfo?.heading}
								fromIndex={sectionInfo?.startNumber}
								toIndex={sectionInfo?.endNumber}
								black={sectionInfo?.hasBlackBackground}
							/>
						)}

						{sectionInfo.fieldGroupName ===
							"Area_Areaspostfields_AreasPostFields_SectionLayout6" && (
							<Container fluid={true} as="section">
								<Row
									className={`${
										sectionInfo?.hasLeftImageAndRightContent
											? "flex-row-reverse"
											: ""
									}`}
								>
									<Col xs={12} xl={6} className="my-24">
										<Row className="justify-content-center">
											<Col xs={11} sm={10}>
												<h2 className="text-3xl lato fw-700 mb-3">
													{sectionInfo?.heading}
												</h2>
												<span className="sl-underline d-block mb-5" />
												<div className="two-column-content">
													{sectionInfo?.description && (
														<SafeHtmlParser
															htmlContent={sectionInfo?.description}
														/>
													)}
												</div>
											</Col>
										</Row>
									</Col>
									<Col xs={12} xl={6} className="px-0">
										<GatsbyImage
											image={
												sectionInfo.image?.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={sectionInfo.image?.altText}
											className="w-100 h-100"
										/>
									</Col>
								</Row>
							</Container>
						)}
						{sectionInfo.fieldGroupName ===
							"Area_Areaspostfields_AreasPostFields_SectionLayout7" && (
							<CallNowBanner
								heading={sectionInfo?.heading}
								button={sectionInfo?.cta1}
								button2={sectionInfo?.cta2}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Area_Areaspostfields_AreasPostFields_SectionLayout8" && (
							<>
								<Container as="section" fluid={true} className="my-24">
									<Row>
										<Col xs={12} className="pb-7 two-column-content">
											<Row className="justify-content-center">
												<Col xs={11} sm={10}>
													<h2
														className={`text-3xl lato fw-700 mb-3 ${
															sectionInfo?.hasCenter ? "text-center" : ""
														}`}
													>
														{sectionInfo?.heading}
													</h2>
													<span
														className={`sl-underline d-block mb-5 ${
															sectionInfo?.hasCenter ? "mx-auto" : ""
														}`}
													/>
													{sectionInfo?.description && (
														<SafeHtmlParser
															htmlContent={sectionInfo?.description}
														/>
													)}
												</Col>
											</Row>
										</Col>
									</Row>
								</Container>
								{sectionInfo?.video && (
									<section>
										<Container fluid={true}>
											<Row className="justify-content-center py-5">
												<Col xs={11} sm={10} xl={8}>
													<div className="iframe-container-new">
														<iframe
															className="responsive-iframe"
															loading="lazy"
															src={sectionInfo?.video}
															title="Locksmith in Hove | Seymour Locksmiths"
															frameborder="0"
															allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
															allowfullscreen
														></iframe>
													</div>
												</Col>
											</Row>
										</Container>
									</section>
								)}
							</>
						)}

						{sectionInfo.fieldGroupName ===
							"Area_Areaspostfields_AreasPostFields_SectionLayout9" && (
							<AreaMap
								heading={sectionInfo?.heading}
								map={sectionInfo?.googleMap}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Area_Areaspostfields_AreasPostFields_SectionLayout10" && (
							<DisplayFaqs
								data={sectionInfo.faqs}
								heading={sectionInfo?.heading}
							/>
						)}
					</>
				))}
		</Layout>
	);
};

export default AreaPostTemplate;

export const pageQuery = graphql`
	query AreaPostById($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		pageData: wpPage(slug: { eq: "home" }) {
			reviewCountFields {
				googleReviewCount
				bwcReviewCount
				checkatradeReviewCount
			}
		}

		schemaLogo: file(relativePath: { eq: "logo.png" }) {
			publicURL
		}
		post: wpArea(id: { eq: $id }) {
			slug
			title
			seoFieldGroups {
				localBusinessSchema
				metaDescription
				metaTitle
				metaKeywords
				openGraphDescription
				openGraphTitle
				productSchema
				image {
					altText
					publicUrl
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
							original {
								width
								height
							}
						}
					}
				}
			}
			areasPostFields {
				hasShowMessageIcon
				areasPostFields {
					... on WpArea_Areaspostfields_AreasPostFields_BannerSection {
						description
						fieldGroupName
						heading
						actions {
							heading
						}
						cta1 {
							target
							title
							url
						}
						cta2 {
							target
							title
							url
						}
						backgroundImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpArea_Areaspostfields_AreasPostFields_SectionLayout2 {
						fieldGroupName
						trustedList {
							type
							starCount
							link {
								target
								title
								url
							}
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpArea_Areaspostfields_AreasPostFields_SectionLayout3 {
						fieldGroupName
						hasBlackBackground
						heading
						description
						link {
							target
							title
							url
						}
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpArea_Areaspostfields_AreasPostFields_SectionLayout4 {
						fieldGroupName
						serviceSectionList {
							heading
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							cta {
								target
								title
								url
							}
						}
					}
					... on WpArea_Areaspostfields_AreasPostFields_SectionLayout5 {
						fieldGroupName
						heading
						endNumber
						startNumber
						hasBlackBackground
					}
					... on WpArea_Areaspostfields_AreasPostFields_SectionLayout6 {
						description
						fieldGroupName
						heading
						hasLeftImageAndRightContent
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpArea_Areaspostfields_AreasPostFields_SectionLayout7 {
						fieldGroupName
						heading
						cta1 {
							target
							title
							url
						}
						cta2 {
							target
							title
							url
						}
					}
					... on WpArea_Areaspostfields_AreasPostFields_SectionLayout8 {
						description
						fieldGroupName
						video
						hasCenter
						heading
					}
					... on WpArea_Areaspostfields_AreasPostFields_SectionLayout9 {
						fieldGroupName
						googleMap
						heading
					}
					... on WpArea_Areaspostfields_AreasPostFields_SectionLayout10 {
						fieldGroupName
						heading
						faqs {
							... on WpFaq {
								id
								faqFieldsGroups {
									answer
									question
								}
							}
						}
					}
				}
			}
		}
	}
`;
